import {
  createBaseThemeOptions,
  createUnifiedTheme, genPageTheme,
  palettes,
} from '@backstage/theme';

export const frankTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      navigation: {
        background: '#101010',
        indicator: '#fdc300',
        color: '#d5d6db',
        selectedColor: '#fdc300',
      },
    },
  }),

  fontFamily: 'Roboto, sans-serif',
  pageTheme: {
    home: genPageTheme({ colors: ['#fdc300'], shape: 'none', options: {fontColor: '#0a0a0a'}  }),
  }
});


